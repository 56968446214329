<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <span class="sp">IP：</span>
            <input type="text" v-model="terminalip" class="input qy r" placeholder="请输入IP" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">确定</div>
        <div class="button1 primary mt" @click="goback">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';

export default {
  created() {
    this.room_code = this.$route.query.room_code;
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.num_computer = this.$route.query.num_computer; 
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      num_computer: '',
      room_code: '',
      terminalip: '',
      newpass: '',
      selectItem: '',
      state:false,
      items: []
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/boss/room_set_ip_list', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        num_computer:this.num_computer,
        room_code:this.room_code
      }});
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.terminalip)) {
        this.state = false;
        Toast('IP不可以为空!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_addroomip',
      qs.stringify({ 
        terminalip: this.terminalip, 
        room_code: this.room_code  
      })
      );
      if (data.flag === '0') {
        this.state = false;
        Toast(data.message);
        this.$router.replace({path:'/boss/room_set_ip_list', query:{
          type:this.type,
          unionid:this.unionid,
          srole:this.srole,
          num_computer:this.num_computer,
          room_code:this.room_code
        }});
      } else {
        this.state = false;
        // Dialog({ message: data.message });
        Toast(data.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.form_select {
  /* 清除默认边框 */
  border: 0;
  /* 清除默认的箭头样式 */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* 右侧添加小箭头的背景图 */
  background: url('/img/arrow_1.png') 98% center no-repeat;
  background-size: 26px;
  width: 100%;
  height: 60px;
  background-color: transparent;
  font-size: 30px;
  color: rgb(136, 134, 134);
  line-height: 60px;
  vertical-align: middle;
  //direction: rtl;
  padding-right: 40px;
} 
select:focus { outline: none; }

.sp {
  margin-left: 5px;
  font-size: 30px;
  width: 339px;
  height: 60px;
  vertical-align: middle;
  line-height: 60px;
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>